/* General styles */
body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
  }
  
  .LandingPage {
    width: 100%;
    overflow-x: hidden;
  }
  
  /* Header */
  .header {
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .header .logo {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .header nav button {
    margin-left: 1rem;
    padding: 0.5rem 1rem;
    background-color: #ff7a00;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .header nav button:hover {
    background-color: #ff5900;
  }
  
  /* Hero Section */
  .hero-section {
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    height: 400px;
    background-image: url('../../assets/herobackgr.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .hero-dating-image {
    width: 165px;
    height: 30px;
    margin:4rem 0 1.5rem  8.5rem;
  }
  .homeimg{
    width: 35%;
    height: 95%;
    margin: 0;
} 
  
  .hero-text {
    text-align: left;
  }
  
  .hero-text h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    font-weight: bolder;
  }
  
  .hero-text p {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
  }
  
  .hero-text button {
    background-color: #C65102;
    border-radius: 5px;
    width: 120px;
    color: white;
    padding: 6px;
    box-shadow: 2px 2px   black;
    border:none;
  }
  
  .hero-text button:hover {
    background-color: #ff5900;
  }
    
  @media (max-width: 768px) {
    .hero-section {
      flex-direction: column;
      justify-content: space-between;
      align-items:center;
      text-align: center;
      height: fit-content;
      background-position:right;
    }
    .hero-dating-image {
        width: 165px;
        height: 40px;
        margin:2rem 0 4rem  1rem ;
      }
      .homeimg{
        width: 100%;
        height: 100%;
        margin: 3rem 0 0 0 ;
     } 
      
      .hero-text {
        text-align: center;
      }
  
  }
  
