/* src/components/header.css */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.1rem  1rem;
  background-color: #3f3d56;
  color: white;
}
.logo-image {
  width: 150px;
  height: 150px;
  margin-left: 2rem;
}
.logo a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}

.nav {
  display: flex;
  gap: 1rem;
}
.not {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}
.nav-button {
  background-color: #C65102;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}
.notification-btns {
  width: 40%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-bottom: 1rem;
  margin-left: 1.5rem;

}
.notification-btns  button{
  background-color: #C65102;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  width: 120px;
  cursor: pointer;
  text-decoration: none;
  font-size: smaller;
}


.notitem {
  font-size: medium;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  
}


@media screen and (max-width: 768px) {
  .header{
    display: block;
    justify-content: space-between;
  }
  .logo{
    text-align: center;
    margin-bottom: 1rem;
  }
  .notification-btns {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr ;
    gap: 20px;
    margin: 1rem  1rem 1rem 0 ;
    text-wrap: nowrap;
  }
  .notitem{
    width: 100%;
    text-wrap: nowrap;
    font-size: 14.5px;
    margin: 0.5rem 0;
  }
}