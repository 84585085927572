.member-info{
    display: flex;
    text-align: center;
}
.members-profile{
    margin-right: 5rem;
    text-align: center;
}
.member-info div{
    margin-left: 4rem;
    text-align: center;
}
.reason-for-searching{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.memberprof{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}