.app {
  font-family: Arial, sans-serif;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 20px 20px 20px;
}

.filter-bar {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;

}

.filter-bar select {
  flex: 1 1 10px; 
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor:pointer;
  font-size: 16px;
}

.banner {
  text-align: center;
  background-color: #f3f3f3;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  height: 290px;
  width: 100% !important;
  z-index: 1;
  border-radius:10px;
  padding-left: 7rem;

}
.malelanding{
  width: 9rem;
  height: 3rem;
  margin: 5rem 0 0  10rem;

}
.banner .malehero{
  width: 25%;
  height: 100%;
  margin-right:15rem;
}
.banner div h2{
  padding: 1rem 3rem;
  font-size: 20;
  font-weight: bolder;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
}

.card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  cursor:pointer;
}

.card img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
  padding: 10px 15px;
  border: none;
  background: #C65102;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}

.pagination button.active {
  background: orange;
}


.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.search-input {
  width: 100%;
  max-width: 600px;
  padding: 10px 20px;
  font-size: 16px;
  border: 2px solid orange;
  border-radius: 25px;
  outline: none;
  transition: border-color 0.3s;
}

.search-input:focus {
  border-color: black; /* You can change the color to match your theme */
}

.search-input::placeholder {
  color: #aaa;
  font-size: 16px;
}

/* Add some basic responsive design */
@media (max-width: 768px) {
  .search-input {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .malehero{
    display: none;
  }
  .banner{
    padding: 0;
  }
}



