.auth-form {
    width: 90%;
    max-width: 400px;
    margin: 5.5rem auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
}

.auth-form h2 {
    margin-bottom: 20px;
    text-align: center;
}

.form-group {
    margin-bottom: 15px;
}

.form-group input {
    width: 93%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.auth-form button {
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 1rem;
}

.auth-form button:hover {
    background-color: #45a049;
}

@media (max-width: 768px) {
    .auth-form {
        width: 80%;
    }
}

@media (max-width: 480px) {
    .auth-form {
        width: 90%;
    }
}
 