.registration-form {
    max-width: 600px;
    margin: 2rem auto;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}
.registration-form h2 {
    text-align: center;
    margin-bottom: 20px;
}
.registration-success{
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.registration-form p {
    text-align: center;
    margin-bottom: 20px;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.form-group-inline {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

.form-group-inline .form-group {
    flex: 1;
    margin-right: 10px;
}

.form-group-inline .form-group:last-child {
    margin-right: 0;
}

.registration-form input,
.registration-form select {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
}
.registration-error{
text-align: center;
color: red;
font-weight: bolder;
}

.registration-form button {
    width: 100%;
    padding: 10px;
    background-color: #C65102;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
}

.registration-form button:hover {
    background-color: #fc6d0d;
}

.registration-form a {
    text-align: center;
    display: block;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
}

.registration-form a:hover {
    text-decoration: underline;
}

.agreement {
    display: grid !important;
    grid-template-columns: 0.2fr 1fr;

    align-items: center;
    margin-bottom: 20px;
}
.agreement div a {
    top: -5px;
    position: relative;
}



.modern-textarea {
    width: 92%;
    height: 100px; /* You can adjust the height as needed */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    resize: vertical;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.modern-textarea:focus {
    border-color: #66afe9;
    box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
    outline: none;
}

.password-group {
    position: relative;
    display: flex;
    align-items: center;
}

.password-group input {
    flex: 1;
    padding-right: 50px; /* Make space for the toggle button */
}

.toggle-password {
    position: absolute;
    right: 10px;
    cursor: pointer;
    user-select: none;
    color: #007bff;
    font-size: 14px;
}

.toggle-password:hover {
    color: #0056b3;
}

@media (max-width: 768px) {
    .form-group-inline .form-group {
        margin-right: 0;
    }
    .form-group-inline {
        display: block;
    }
    .form-group-inline .form-group input, 
    .form-group-inline .form-group select {
        width: 90%;
    }
    .selection {
        width: 100%;
    }
}
