.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    width: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    max-height: 90vh;
}

.modal h2 {
    margin-top: 0;
}

.modal button {
    margin-right: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal button:first-of-type {
    background: #2E3192;
    color: #fff;
}

.modal button:last-of-type {
    background: #CD3700;
    color: #fff;
}


.privacy-policy-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 20px;
}

.privacy-policy-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 800px;
    width: 100%;
    margin: 20px;
}

.privacy-policy-card h2, .privacy-policy-card h3 {
    margin-top: 0;
}

.privacy-policy-card ul {
    padding-left: 20px;
}

.privacy-policy-card li {
    margin-bottom: 10px;
}

.privacy-policy-card p {
    line-height: 1.6;
}

.privacy-policy-card a {
    color: #2E3192;
    text-decoration: none;
}

.privacy-policy-card a:hover {
    text-decoration: underline;
}


.terms-conditions-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 20px;
}

.terms-conditions-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 800px;
    width: 100%;
    margin: 20px;
}

.terms-conditions-card h2, .terms-conditions-card h3 {
    margin-top: 0;
    
}

.terms-conditions-card ul {
    padding-left: 20px;
}

.terms-conditions-card li {
    margin-bottom: 10px;
}

.terms-conditions-card p {
    line-height: 1.6;
}

.terms-conditions-card a {
    color: #2E3192;
    text-decoration: none;
}

.terms-conditions-card a:hover {
    text-decoration: underline;
}
