.footer {
  background-color: #3f3d56;
  padding: 20px 0;
  color: white;
  width: 100vw;
}

.copyright{
  text-align: center;
}

