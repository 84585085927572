.profile-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .profile-header {
    background-color: #3f3d56;
    color: white;
    border-radius: 10px;
    width: 100%;
    max-width: 800px;
    margin: 10px 20px;
    box-shadow: 0 0 5px black;
  }
  
  .profile-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 1rem 0;
  }
  
  .profile-img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    
  }
  .edit-profile-btn {
    background-color: #C65102;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  .profile{
    background-color: #f9f9f9;
    padding: 30px;
    border-radius: 10px;
    margin: 10px 0;
    width: 100%;
    max-width: 800px;
    display: block;
    box-shadow: 0 0 5px black;

  }
  .profile-details {
    padding: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    flex-wrap: wrap;
    text-wrap: wrap;
  }
  
  
  .profile-details-item {
    text-wrap: wrap;
    width: 100%;
  }
  .reason-for-searching{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  } 
  
  .approval-section {
    display:block;
    padding: 0 5px;
    width: 100%;
    max-width: 950px;
    box-shadow: 0 0 5px black;
    border-radius: 10px;
    font-size: 13px;
  }
  .view-profile-btn,
  .cancel-request-btn {
    background-color: #C65102;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 2px;
    cursor: pointer;
    width: 40%;
    font-size: small;

  }

  .profile-form {
    display: grid;
    grid-template-columns: 1fr 1fr ;
    padding: 20px;
    width: 100%;
    max-width: 950px;
    box-shadow: 0 0 5px black;
    border-radius: 10px;
    font-size: 13px;
    margin: 1rem 0 ;
    gap: 20px;
  }
  .profile-form .form-group input, .profile-form .form-group select{
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  .profile-form .form-group input  {
    width: 95%;

  }
  

  .modern-textarea {
    width: 92%;
    height: 100px; /* You can adjust the height as needed */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    resize: vertical;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.modern-textarea:focus {
    border-color: #66afe9;
    box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
    outline: none;
}
  @media screen and (max-width: 768px) {
    .profile-details-item {
      display: flex;
      flex-direction: column;
      margin: 10px;
    }
    .profile-details{
      padding: 20px;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      flex-wrap: wrap;
      text-wrap: wrap;

    }
    .profile-form{
      width: 90%;
    }
  }





